import { FetchError } from './fetchWrapper';
import {
  LocalStorageItems,
  PageNames,
  getItem,
  setItem
} from './generalUtilities';
import User, { UserInfo } from '../models/User';
import firebase from 'firebase/app';

type FirebaseUserInfo = {
  uid: string;
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
  phoneNumber: string | null;
  providerId: string;
};
/**
 * A Client side function for setting up redirection, in case user didn't finish setting up the profile
 *
 * @param {string} accessToken
 * @param {string} [finalDestination] If `finalDestination` === '', we won't redirect to any page, by default we will redirect to user's profile.
 * @param {(UserInfo & FetchError)} [userData] If the parent function has a userData, we can skip the extra retrieval for faster loading
 * @return {*}
 */
const initializeLocalStorage = async (
  userInfo: firebase.User,
  finalDestination?: string
) => {
  try {
    setItem(LocalStorageItems.URL_SLUG, userInfo.uid);
    setItem(LocalStorageItems.DISPLAY_NAME, userInfo.displayName);
    if (!window) throw 'window not found';
    let dataToUpdate: any = {};
    if (!userInfo.displayName.length)
      dataToUpdate.displayName = dataToUpdate.email;
    if (Object.keys(dataToUpdate).length)
      await User.update(userInfo.uid, dataToUpdate);
  } finally {
    if (!finalDestination) return (window.location.href = PageNames.ME);
    return (window.location.href = finalDestination);
  }
};

export { initializeLocalStorage };
